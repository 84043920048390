* {
  box-sizing: border-box;
}

body{
  margin: 0;
  padding: 0;
}


.form-sections {
  padding: 15px;
  border: 0.5px solid #e4e4e4;
  margin: 30px 15px !important;
}

.mycheckbox {
  margin-right: 1.5em !important;
}

.DraftEditor-editorContainer {
  height: 250px !important;
}

.multiple-images-container {}

.single-image-block {}

.FloorCheckbox {
  border: 1px solid #e4e4e4;
  padding: 10px;
  margin-bottom: 10px;
}

.All_rooms_Block {
  margin: 10px 0px;
}

.single_room {
  align-items: center;
  border: 1px solid #e4e4e4;
  display: inline-flex;
  padding: 2px 10px;
  margin-right: 10px;
  margin-bottom: 10px;

}

.EditFloorBlock {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

}

a:hover {
  text-decoration: none !important;
}


.property-img-block {
  display: flex;
  flex-wrap: wrap;


}

.property-single-img-block {
  width: 75px;
  margin: 10px;
}

.verticle_center_table table td{
  vertical-align: middle;
}

.single_line_text{
  white-space:nowrap;
}