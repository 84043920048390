.breadcrumb{
    background-color: #e9ecef;
    padding: .75rem 1rem;
    border-radius: .25rem;
    display: flex;
    align-items: center;
}

.link{
    text-decoration: none !important;
}

.splash{
    padding: 0 8px;
    color: #6c757d;
}

.splashTxt {
    padding: 0;
    color: #6c757d;
}